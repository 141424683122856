/* Override CSS on project */

/* PLP PDP */
.product-detail .cms-block-banner-promo .img.mgz-single-image,
.product-detail .banner-sidebar .img.mgz-single-image {
    width: 100%;
}
.product-detail .image-slider-preview .container-image-slider-parent > span {
    height: auto !important;
}

/* safari handle */
summary:focus {
    outline: none;
}
summary::-webkit-details-marker {
    display: none;
}