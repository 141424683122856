@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(custom.css);


@layer base {
    body {
        @apply text-base leading-base text-neutral
    }
}

@layer components {
    .prose {
        @apply text-base leading-base text-neutral
    }

    .prose > p > a {
        @apply font-normal text-base leading-base text-neutral
    }
}

/* Google maps style required */
.pac-container {
    z-index: 1300;
    border: 1px solid #f0f0f0;
    background: #ffffff;
    box-shadow: none;
}

/* Remove background color autofill */
input:-webkit-autofill{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}